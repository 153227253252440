<template>
  <div class="home">
    <div class="swiper-container" ref="swiperContainer">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img
            style="width: 101%; cursor:pointer"
            src="../../assets/slide_1.png"
            alt="1"
            @click="jumpToChat(0)"
          />
        </div>
        <div class="swiper-slide">
          <img
            style="width: 101%; cursor:pointer"
            src="../../assets/slide_2.png"
            alt="2"
            @click="jumpToChat(0)"
          />
        </div>
        <div class="swiper-slide">
          <img
            style="width: 101%; cursor:pointer"
            src="../../assets/slide_3.png"
            alt="3"
            @click="jumpToChat(1)"
          />
        </div>
        <div class="swiper-slide">
          <img
            style="width: 101%; cursor:pointer"
            src="../../assets/slide_4.png"
            alt="3"
            @click="jumpToDraft"
          />
        </div>
      </div>
      <!-- 添加左右按钮 -->
      <div class="swiper-button-next" @click="nextSlide"></div>
      <div class="swiper-button-prev" @click="prevSlide"></div>
    </div>
    <!-- <section class="intro">
      <h1 class="title">矿业法律问题？LegalMineGPT随时待命！</h1>
      <div class="desc">
        当您的矿业企业面临法律问题时，如何快速、高效地解决这些问题，确保您的业务不受影响？LegaMineGPT将为您的最佳法律合作伙伴，提供全方位、智能化的法律咨询服务，帮助您在矿业领域中合规运营，规避风险，推动业务发展。无论您是矿业公司、投资者还是政府机构，我们都能为您提供高效准确的法律解答，让您放心经营，安心发展。
      </div>
      <button class="jump-to-chat-btn" @click="jumpToChat">立即咨询</button>
    </section> -->

    <div class="sc-dHIava jNSKTG">
      <section id="section-4bf516a2" class="sc-eLdqWK epmnoT">
        <div width="wide" class="sc-ejGVNB ixefHL">
          <div class="sc-dHIava cDRtXJ">
            <div id="row-be66f937" class="sc-hcmgZB EAAub">
              <div size="1" class="sc-sPYgB bSkAlH"></div>
              <div size="10" class="sc-sPYgB iRgSWM">
                <div class="sc-guztPN hplxCI">
                  <div
                    id="headline-06299068"
                    font-size="62"
                    color="rgba(17, 17, 17, 1)"
                    font-family="InterBold"
                    font-style="normal"
                    font-weight="400"
                    letter-spacing="-3"
                    class="sc-cooIXK kznxsL"
                  >
                    <p dir="ltr">矿业法律问题？ LegalMineGPT随时待命！</p>
                  </div>
                </div>
                <div class="sc-dHIava fDnbHW">
                  <div
                    id="text-62761598"
                    font-size="20"
                    font-family="Inter"
                    color="rgba(102, 102, 102, 1)"
                    class="sc-cooIXK bzrrWh"
                  >
                    <div>
                      <p dir="ltr">
                        当您的矿业企业面临法律问题时，如何快速、高效地解决这些问题，确保您的业务不受影响？LegalMineGPT将成为您的最佳法律合作伙伴，提供全方位、智能化的法律咨询服务，帮助您在矿业领域中合规运营，规避风险，推动业务发展。无论您是矿业公司、投资者还是政府机构，我们都能为您提供高效准确的法律解答，让您放心经营，安心发展。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div size="1" class="sc-sPYgB gqAbcT"></div>
            </div>
          </div>
          <div class="sc-dHIava cDRtXJ">
            <div id="row-ecaecfab" class="sc-hcmgZB fhBPKm">
              <div size="12" class="sc-sPYgB iZVacQ">
                <div class="sc-guztPN bDuFbF">
                  <div class="sc-bEjcJn fgaaxa">
                    <picture class="sc-ePZHVD QVkdj"
                      ><img
                        id="image-20bcc678"
                        src="../../assets/1.png"
                        width="971"
                        loading="lazy"
                        class="sc-bGbJRg hhIXUe"
                    /></picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sc-dHIava cDRtXJ">
            <div id="row-cb316485" class="sc-hcmgZB IgvUx">
              <div size="12" class="sc-sPYgB iZVacQ">
                <div class="sc-dHIava cEEsxv">
                  <button
                    id="button-53d06bbf"
                    font-family="InterSemiBold"
                    class="sc-cvbbAY bGDoVD"
                    @click="jumpToChat(0)"
                  >
                    <div class="sc-jWBwVP gvUqdv">立即咨询</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- <section class="chat-preview">
      <div class="chat-page">
        <div class="message answer">
          <div class="avatar">
            <img src="../../assets/ai_avatar.png" alt="avatar" />
          </div>

          <div class="content">
            <div class="date">2023/5/12 16:10</div>
            <div class="text">
              雨，水之源；仁，人之本。欢迎使用雨仁法律AI助手，有什么可以帮助到您？
            </div>
          </div>
        </div>

        <div class="input">请输入您想咨询的矿业法律问题</div>
      </div>
    </section> -->

    <!-- <section class="detail-intro">
      <div class="intro-item" style="margin-bottom: 6%">
        <div class="desc-wrap">
          <h1 class="title">
            掌握矿业法规，从容应对挑战 — 打造最强法律咨询AI助手
          </h1>
          <div class="desc">
            对于矿业企业而言，遵守法律规定是至关重要的。但是，由于矿业法律领域的复杂性和多样性，矿业企业常常会遇到各种各样的法律问题和挑战。此时，我们的法律咨询AI助手就可以帮助您快速找到答案，解决您的疑惑和问题。无论您是涉及矿权取得和转让、环境保护、安全生产还是税收政策等方面的问题，我们的AI助手都可以提供全面、专业和实用的法律建议，为您的企业保驾护航。
          </div>
        </div>
        <div class="img-wrap">
          <img class="img" src="../../assets/ai1.png" alt="" />
        </div>
      </div>
      <div class="intro-item reverse">
        <div class="desc-wrap">
          <h1 class="title">
            LegalMineGPT，满足你对矿业法规的一切需求 —
            领先矿业法律咨询服务的新选择
          </h1>
          <div class="desc">
            我们的矿业法律咨询AI助手不仅具有高效、智能和精准的特点，还可以实现随时随地在线咨询，为您提供即时的法律服务。不再需要费时费力地查阅繁琐的法律文献和资料，不再需要担心法律风险和合规问题。与传统的法律咨询服务相比，我们的AI助手不仅更为方便快捷，而且能够帮助您节省成本和时间，提高企业的工作效率和核心竞争力。让我们的AI助手成为您的矿业法律顾问，为您的企业保驾护航！
          </div>
        </div>
        <div class="img-wrap">
          <img class="img" src="../../assets/ai2.png" alt="" />
        </div>
      </div>
    </section> -->
    <div class="sc-dHIava ieyDjz">
      <section id="section-65e4d3a7" class="sc-eLdqWK eJrUdH">
        <div width="wide" class="sc-ejGVNB ixefHL">
          <div class="sc-dHIava cDRtXJ">
            <div id="row-0fef7835" class="sc-hcmgZB csMzVy">
              <div size="12" class="sc-sPYgB iZVacQ">
                <div class="sc-dHIava cDRtXJ">
                  <div id="row-2489edb5" class="sc-hcmgZB kKOQTN">
                    <div size="6" class="sc-sPYgB gPGeRt">
                      <div class="sc-guztPN bvyQFJ">
                        <div class="sc-bEjcJn jezVrB">
                          <picture class="sc-ePZHVD dIqBze"
                            ><img
                              id="image-764b656a"
                              src="../../assets/2.png"
                              width="540"
                              loading="lazy"
                              class="sc-bGbJRg eLEaKC"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div size="6" class="sc-sPYgB dHDOqC">
                      <div class="sc-guztPN cIthNr">
                        <div
                          id="headline-895bbaf9"
                          font-size="20"
                          color="rgba(17, 17, 17, 1)"
                          font-family="InterBold"
                          font-style="normal"
                          font-weight="400"
                          class="sc-cooIXK hojTyf"
                        >
                          <h5 dir="ltr">
                            <span style="color: rgba(245, 166, 35, 1)"
                              ><strong>深度洞察，专业法律指导</strong></span
                            >
                          </h5>
                        </div>
                      </div>
                      <div class="sc-guztPN cIthNr">
                        <div
                          id="headline-f32a75e9"
                          font-size="34"
                          color="rgba(17, 17, 17, 1)"
                          font-family="InterBold"
                          font-style="normal"
                          font-weight="400"
                          class="sc-cooIXK fMsrgN"
                        >
                          <p dir="ltr">
                            <strong
                              >解锁矿业法律专业咨询，为您的业务发展保驾护航</strong
                            >
                          </p>
                        </div>
                      </div>
                      <div class="sc-dHIava jxlMFl">
                        <div
                          id="text-a9c06261"
                          font-size="18"
                          font-family="Inter"
                          color="rgba(102, 102, 102, 1)"
                          class="sc-cooIXK fbLrCr"
                        >
                          <div>
                            <p dir="ltr">
                              专业矿业法律咨询，只需点击几下，LegalMineGPT即可为您提供矿业法律的专业解答。我们深入理解矿业法律，帮助用户快速解决法律难题，让您专注于业务发展，降低法律风险。无论您是矿业初创企业，还是有深厚矿业背景的公司，LegalMineGPT都能为您提供量身定制的法律咨询服务。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sc-dHIava cDRtXJ">
                  <div id="row-0597a0b6" class="sc-hcmgZB hHjwAs">
                    <div size="6" class="sc-sPYgB gPGeRt">
                      <div class="sc-guztPN cIthNr">
                        <div
                          id="headline-e3f64540"
                          font-size="20"
                          color="rgba(17, 17, 17, 1)"
                          font-family="InterBold"
                          font-style="normal"
                          font-weight="400"
                          class="sc-cooIXK cCttZP"
                        >
                          <h5 dir="ltr">
                            <span style="color: rgba(245, 166, 35, 1)"
                              ><strong>法规掌握，一触即达</strong></span
                            >
                          </h5>
                        </div>
                      </div>
                      <div class="sc-guztPN cIthNr">
                        <div
                          id="headline-cad677d2"
                          font-size="34"
                          color="rgba(17, 17, 17, 1)"
                          font-family="InterBold"
                          font-style="normal"
                          font-weight="400"
                          class="sc-cooIXK ekKOuy"
                        >
                          <p dir="ltr">
                            <strong
                              >实时获取矿业法规信息，为您的决策提供强大支持</strong
                            >
                          </p>
                        </div>
                      </div>
                      <div class="sc-dHIava jxlMFl">
                        <div
                          id="text-1ec4a2f2"
                          font-size="18"
                          font-family="Inter"
                          color="rgba(102, 102, 102, 1)"
                          class="sc-cooIXK gMJANh"
                        >
                          <div>
                            <p dir="ltr">
                              LegalMineGPT的法规查询功能涵盖了矿业相关的法律法规。我们的AI助手通过高效搜索，确保您在需要时迅速获取最新、最全面的矿业法规信息。LegalMineGPT不仅可以提供法规的文字描述，还能为您解析其具体含义和应用场景，帮助您全面理解和应对法规变动。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div size="6" class="sc-sPYgB dHDOqC">
                      <div class="sc-guztPN dZCzyk">
                        <div class="sc-bEjcJn fgaaxa">
                          <picture class="sc-ePZHVD dIqBze"
                            ><img
                              id="image-7b8cb5c4"
                              src="../../assets/3.png"
                              width="540"
                              loading="lazy"
                              class="sc-bGbJRg eLEaKC"
                          /></picture>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sc-dHIava cDRtXJ">
                  <div id="row-7207ce16" class="sc-hcmgZB kKOQTN">
                    <div size="6" class="sc-sPYgB gPGeRt">
                      <div class="sc-guztPN bvyQFJ">
                        <div class="sc-bEjcJn jezVrB">
                          <picture class="sc-ePZHVD blwmKf"
                            ><img
                              id="image-2d9fdf06"
                              src="../../assets/4.png"
                              width="540"
                              loading="lazy"
                              class="sc-bGbJRg eLEaKC"
                          /></picture>
                        </div>
                      </div>
                    </div>
                    <div size="6" class="sc-sPYgB dHDOqC">
                      <div class="sc-guztPN cIthNr">
                        <div
                          id="headline-058bf13a"
                          font-size="20"
                          color="rgba(17, 17, 17, 1)"
                          font-family="InterBold"
                          font-style="normal"
                          font-weight="400"
                          class="sc-cooIXK hojTyf"
                        >
                          <h5 dir="ltr">
                            <span style="color: rgba(245, 166, 35, 1)"
                              ><strong>合同审查，洞悉风险</strong></span
                            >
                          </h5>
                        </div>
                      </div>
                      <div class="sc-guztPN cIthNr">
                        <div
                          id="headline-39024eef"
                          font-size="34"
                          color="rgba(17, 17, 17, 1)"
                          font-family="InterBold"
                          font-style="normal"
                          font-weight="400"
                          class="sc-cooIXK fMsrgN"
                        >
                          <p dir="ltr">
                            <strong
                              >一键智能审查矿业合同，避免潜在法律风险</strong
                            >
                          </p>
                        </div>
                      </div>
                      <div class="sc-dHIava jxlMFl">
                        <div
                          id="text-2e21aecd"
                          font-size="18"
                          font-family="Inter"
                          color="rgba(102, 102, 102, 1)"
                          class="sc-cooIXK fbLrCr"
                        >
                          <div>
                            <p dir="ltr">
                              专为矿业定制的合同审查功能，LegalMineGPT可以对各类矿业合同进行快速审核，发现可能存在的风险点和问题，帮助您预防法律风险。我们的AI助手对矿业合同的特殊性有深入理解，可以帮您节省大量的审核时间，提高工作效率。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sc-dHIava cDRtXJ">
                  <div id="row-1a1c1435" class="sc-hcmgZB hHjwAs">
                    <div size="6" class="sc-sPYgB gPGeRt">
                      <div class="sc-guztPN cIthNr">
                        <div
                          id="headline-0fcf4851"
                          font-size="20"
                          color="rgba(17, 17, 17, 1)"
                          font-family="InterBold"
                          font-style="normal"
                          font-weight="400"
                          class="sc-cooIXK cCttZP"
                        >
                          <h5 dir="ltr">
                            <span style="color: rgba(245, 166, 35, 1)"
                              ><strong>法律文书，精准无误</strong></span
                            >
                          </h5>
                        </div>
                      </div>
                      <div class="sc-guztPN cIthNr">
                        <div
                          id="headline-ecfdc0e4"
                          font-size="34"
                          color="rgba(17, 17, 17, 1)"
                          font-family="InterBold"
                          font-style="normal"
                          font-weight="400"
                          class="sc-cooIXK ekKOuy"
                        >
                          <p dir="ltr">
                            <strong
                              >快速生成专业矿业法律文书，让法律工作更轻松</strong
                            >
                          </p>
                        </div>
                      </div>
                      <div class="sc-dHIava jxlMFl">
                        <div
                          id="text-fe453b56"
                          font-size="18"
                          font-family="Inter"
                          color="rgba(102, 102, 102, 1)"
                          class="sc-cooIXK gMJANh"
                        >
                          <div>
                            <p dir="ltr">
                              LegalMineGPT的法规查询功能涵盖了全球矿业相关的法律法规。我们的AI助手通过高效搜索，确保您在需要时迅速获取最新、最全面的矿业法规信息。LegalMineGPT不仅可以提供法规的文字描述，还能为您解析其具体含义和应用场景，帮助您全面理解和应对法规变动。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div size="6" class="sc-sPYgB dHDOqC">
                      <div class="sc-guztPN dZCzyk">
                        <div class="sc-bEjcJn fgaaxa">
                          <picture class="sc-ePZHVD dIqBze"
                            ><img
                              id="image-aac77166"
                              src="../../assets/5.png"
                              width="540"
                              loading="lazy"
                              class="sc-bGbJRg eLEaKC"
                          /></picture>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="sc-dHIava fOcChx">
      <section id="section-23bf807d" class="sc-eLdqWK kFOJwP">
        <div width="wide" class="sc-ejGVNB ixefHL">
          <div class="sc-dHIava cDRtXJ">
            <div id="row-f6f2b1f2" class="sc-hcmgZB iHOpMX">
              <div size="12" class="sc-sPYgB iZVacQ">
                <div class="sc-guztPN iVtKCp">
                  <div
                    id="headline-c1dce0fa"
                    font-size="24"
                    color="rgba(17, 17, 17, 1)"
                    font-family="InterBold"
                    font-style="normal"
                    font-weight="400"
                    letter-spacing="-1"
                    class="sc-cooIXK RQGJd"
                  >
                    <p dir="ltr">
                      <span style="color: rgba(255, 255, 255, 1);font-weight:bold;"
                        >LegalMineGPT - 挖掘您的法律价值，保障矿业的未来</span
                      >
                    </p>
                  </div>
                </div>
                <div class="sc-guztPN dbEuwm">
                  <div
                    id="headline-e500929d"
                    font-size="66"
                    color="rgba(17, 17, 17, 1)"
                    font-family="InterBold"
                    font-style="normal"
                    font-weight="400"
                    letter-spacing="-1"
                    class="sc-cooIXK jCLzJT"
                  >
                    <p dir="ltr">
                      <span style="color: #FF914D">矿业法律专家系统</span>
                    </p>
                  </div>
                </div>
                <div class="sc-guztPN dbEuwm">
                  <div
                    id="headline-476b647c"
                    font-size="66"
                    color="rgba(17, 17, 17, 1)"
                    font-family="InterBold"
                    font-style="normal"
                    font-weight="400"
                    letter-spacing="-1"
                    class="sc-cooIXK jCLzJT"
                  >
                    <p dir="ltr">
                      <span style="color: #FF914D">全球矿业法规实时探索</span>
                    </p>
                  </div>
                </div>
                <div class="sc-guztPN dbEuwm">
                  <div
                    id="headline-51d65e16"
                    font-size="66"
                    color="rgba(17, 17, 17, 1)"
                    font-family="InterBold"
                    font-style="normal"
                    font-weight="400"
                    letter-spacing="-1"
                    class="sc-cooIXK jCLzJT"
                  >
                    <p dir="ltr">
                      <span style="color: #FF914D">矿业合同智能分析</span>
                    </p>
                  </div>
                </div>
                <div class="sc-guztPN Ecvxx">
                  <div
                    id="headline-46db6863"
                    font-size="66"
                    color="rgba(17, 17, 17, 1)"
                    font-family="InterBold"
                    font-style="normal"
                    font-weight="400"
                    letter-spacing="-1"
                    class="sc-cooIXK jCLzJT"
                  >
                    <p dir="ltr">
                      <span style="color: #FF914D">个性化法律文件定制</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section class="function">
      <div class="func-wrap">
        <div class="func-item">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-dingzhihuatixingfuwu"></use>
          </svg>
          <h1 class="title">定制化提醒服务</h1>
          <div class="desc">
            LegalMineGPT可以根据用户关注的矿业领域或法规，及时向用户推送相关更新或变化。用户可以定制提醒频率和方式，保持对法规的敏锐感知，规避潜在的法律风险。
          </div>
        </div>
        <div class="func-item">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-faguijiedufuwu"></use>
          </svg>
          <h1 class="title">法规解读服务</h1>
          <div class="desc">
            LegalMineGPT可以对复杂或模糊的法规进行解读，帮助用户理解其中的规定和要求。用户可以通过AI助手获取更加深入和准确的理解，以便更好地应对法规的执行和应用。
          </div>
        </div>
        <div class="func-item">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-shishibiduifuwu"></use>
          </svg>
          <h1 class="title">实时比对服务</h1>
          <div class="desc">
            LegalMineGPT可以帮助用户将项目的法律要求与当地法规进行比对，以确保项目的合规性。用户可以通过AI助手快速了解当地的法律环境和要求，以便更好地制定项目策略和决策。
          </div>
        </div>
        <div class="func-item">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-kuajingfaguichaxun"></use>
          </svg>
          <h1 class="title">跨境法规查询</h1>
          <div class="desc">
            LegalMineGPT可以提供全球范围内的法规查询服务，帮助用户了解跨境矿业项目的法律要求和规定。用户可以通过AI助手获取当地的法律环境和要求，以便更好地应对跨境矿业项目的挑战和风险。
          </div>
        </div>
        <div class="func-item">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-zhishitupuchaxun"></use>
          </svg>
          <h1 class="title">定制化提醒服务</h1>
          <div class="desc">
            LegalMineGPT可以根据用户关注的矿业领域或法规，及时向用户推送相关更新或变化。用户可以定制提醒频率和方式，保持对法规的敏锐感知，规避潜在的法律风险。
          </div>
        </div>
        <div class="func-item">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-faguipinggufuwu"></use>
          </svg>
          <h1 class="title">法规评估服务</h1>
          <div class="desc">
            LegalMineGPT可以对用户提交的法规问题进行评估和分析，提供专业的法律意见和建议。用户可以通过AI助手获取更加深入和准确的理解，以便更好地应对复杂的法律问题和挑战。
          </div>
        </div>
      </div>
    </section>

    <div class="sc-dHIava hctQUW">
      <section id="section-a066c1d8" class="sc-eLdqWK eFGVkq">
        <div width="wide" class="sc-ejGVNB ixefHL">
          <div class="sc-dHIava cDRtXJ">
            <div id="row-09e1ca4b" class="sc-hcmgZB iHOpMX">
              <div size="12" class="sc-sPYgB iZVacQ">
                <div class="sc-dHIava cDRtXJ">
                  <div id="row-636d1f7d" class="sc-hcmgZB hnVrSq">
                    <div size="2" class="sc-sPYgB eSkcLK"></div>
                    <div size="8" class="sc-sPYgB kscyLG">
                      <div class="sc-guztPN hplxCI">
                        <div
                          id="headline-002096c7"
                          font-size="62"
                          color="rgba(17, 17, 17, 1)"
                          font-family="InterBold"
                          font-style="normal"
                          font-weight="400"
                          letter-spacing="-1"
                          class="sc-cooIXK GzvSW"
                        >
                          <p dir="ltr">
                            <span style="color: #FF914D"
                              >LegalMineGPT - 开启您的矿业法律优势之旅</span
                            >
                          </p>
                        </div>
                      </div>
                      <div class="sc-dHIava fDnbHW">
                        <div
                          id="text-d73f40a3"
                          font-size="20"
                          font-family="Inter"
                          color="rgba(102, 102, 102, 1)"
                          class="sc-cooIXK bzrrWh"
                        >
                          <div>
                            <p dir="ltr">
                              <span style="color: rgba(255, 255, 255, 1)"
                                >专业、精准、快捷的法律AI助手，全面满足您的矿业法律需求。立即体验
                                LegalMineGPT，感受矿业法律服务的革新。</span
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="sc-dHIava cEEsxv">
                        <button
                          id="button-a1a62758"
                          font-family="InterSemiBold"
                          class="sc-cvbbAY iGemEQ"
                          @click="jumpToChat(0)"
                        >
                          <div class="sc-jWBwVP gvUqdv">
                            立即咨询
                          </div>
                        </button>
                      </div>
                    </div>
                    <div size="2" class="sc-sPYgB rdkMG"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

const store = useStore();
const router = useRouter();

let token = computed(() => store.state.token);
const jumpToChat = (v) => { // 0：法律法规查询  1：合同审查  2：文书草拟
  if (token.value) {
    router.push({
      path: "/chat",
      query: { cur: v }
    });
  } else {
    store.commit("setIsLoginBoxShow", true);
  }
};
const jumpToDraft = (v) => {
  if (token.value) {
    router.push({
      path: "/drafting"
    });
  } else {
    store.commit("setIsLoginBoxShow", true);
  }
};

const swiperContainer = ref(null);
let swiper = null;
const nextSlide = () => {
  if (swiper) {
    swiper.slideNext();
  }
};

const prevSlide = () => {
  if (swiper) {
    swiper.slidePrev();
  }
};

let timmer = null
const autoPlay = () => {
  timmer = setInterval(() => {
    swiper.slideNext()
  }, 5000);
}

onMounted(() => {
  swiper = new Swiper(swiperContainer.value, {
    loop: true,
    /* autoplay: { // 不生效估计版本问题
      delay: 2000
    } */
  });
  autoPlay()
});
onBeforeUnmount(() => {
  clearInterval(timmer);
});

</script>

<style lang="less" scoped>
.swiper-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    color: #fff;
    cursor: pointer;
  }

  .swiper-button-next {
    right: 10px;
  }

  .swiper-button-prev {
    left: 10px;
  }
}
.home {
}
.intro,
.chat-preview,
.detail-intro,
.function {
  padding: 60px 40px;
}
.intro {
  background: #9f9f9f;
  background-image: url("../../assets/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  .title {
    font-size: 3.1vw;
    font-weight: 700;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    text-align: center;
    white-space: nowrap;
    padding: 7% 0 6%;
  }
  .desc {
    font-size: 1.1vw;
    font-weight: 400;
    text-align: center;
    line-height: 2vw;
    margin-bottom: 7%;
  }

  .jump-to-chat-btn {
    padding: 1.4% 4%;
    background: #f19135;
    border-radius: 6px;
    outline: none;
    border: 0;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10%;
    color: #fff;
    font-size: 1.2vw;
    cursor: pointer;
  }
}
.chat-preview {
  // padding-top: 4%;
  // padding-bottom: 4%;
  background: #f4f4f4;

  .chat-page {
    // padding: 4%;
    padding: 8% 4%;
    border-radius: 6px;

    max-width: 1120px;
    width: 100%;
    margin: auto;
    background: #fff;
    box-sizing: border-box;
  }

  .message {
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-start;
    .content {
      margin-left: 10px;
      max-width: 80%;
      .text {
        background: #f6f6f6;
        display: inline-block;
        padding: 14px;
        font-size: 16px;
        margin-top: 6px;
        border-radius: 6px;
        color: #1d1e22;
      }
      .date {
        font-size: 16px;
        color: #b6b6b6;
      }
    }

    .avatar {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .input {
    height: 44px;
    line-height: 44px;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    color: #b8b6b6;
    text-indent: 10px;
    margin-top: 10%;
    font-size: 14px;
  }
}
.detail-intro {
  padding-top: 5%;
  padding-bottom: 5%;

  .intro-item {
    display: flex;
    width: 100%;
    max-width: 1120px;
    margin: auto;

    &.reverse {
      flex-direction: row-reverse;
      .img-wrap {
        text-align: left;
      }
    }

    .desc-wrap {
      flex: 0.9;
      margin-right: 1%;

      .title {
        font-size: 28px;
        font-weight: 700;
        color: #1d1e22;
        line-height: 36px;
      }
      .desc {
        font-size: 16px;
        color: #1d1e22;
        line-height: 28px;
        margin-top: 3%;
      }
    }
    .img-wrap {
      flex: 1;
      height: 320px;
      text-align: right;

      .img {
        width: 540px;
        // width: 92%;
        // height: 92%;
      }
    }
  }
}
.function {
  padding: 60px 40px 80px;
  // background: #f4f4f4;

  .func-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    max-width: 1120px;
    width: 100%;
    margin: auto;
  }
  .func-item {
    // text-align: center;
    padding: 30px 30px 39px;
    background: #fff;
    border-radius: 6px;
    padding-bottom: 10%;
    // box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px, rgba(0, 0, 0, 0.05) 0px 12px 24px 0px;

    .icon {
      font-size: 46px;
      margin-top: 10%;
      // padding-left: 20px;
    }
    .title {
      font-size: 24px;
      margin: 24px 0 16px;
      // padding-left: 20px;
      font-weight: bold;
    }
    .desc {
      // padding: 0 20px;
      text-align: left;
      font-size: 18px;
      line-height: 28px;
      color: rgb(102, 102, 102);
    }
  }
}

.epmnoT {
  position: static;
  display: flex;
  padding: 60px 40px;
  background-size: initial;
  background-color: rgb(248, 248, 248);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
}
.ixefHL {
  max-width: 1120px;
  width: 100%;
  margin: auto;
  background-color: transparent;
}
.EAAub {
  display: grid;
  -webkit-box-pack: justify;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  padding: 0px;
  column-gap: 20px;
  margin: 20px 0px 0px;
  background-size: initial;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
}
.bSkAlH {
  display: flex;
  flex-direction: column;
  grid-column: ~"1/2";
}
.iRgSWM {
  display: flex;
  flex-direction: column;
  grid-column: ~"2/12";
}
.gqAbcT {
  display: flex;
  flex-direction: column;
  grid-column: ~"12/13";
}
.jNSKTG {
  margin: 0;
}
.hplxCI {
  text-align: initial;
  margin: 0px 0px 24px;
}
.fDnbHW {
  margin: 0px 0px 32px;
}
.kznxsL {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 62px;
  color: rgb(17, 17, 17);
  font-family: InterBold;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -3px;
  text-align: center;
  line-height: 70px;
}
.bzrrWh {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 20px;
  color: rgb(102, 102, 102);
  font-family: Inter;
  text-align: center;
  line-height: 32px;
}

.fhBPKm {
  display: grid;
  -webkit-box-pack: justify;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  padding: 10px;
  margin: 0px;
  background-size: initial;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
  column-gap: unset !important;
}
.iZVacQ {
  display: flex;
  flex-direction: column;
  grid-column: ~"1/13";
}
.bDuFbF {
  text-align: initial;
  display: flex;
  flex-direction: column;
  margin: 20px 0px 0px;
}
.fgaaxa {
  align-self: center;
}
.QVkdj {
  display: block;
  position: relative;
  height: 0px;
  overflow: visible;
  padding-bottom: 52.08%;
}
.IgvUx {
  display: grid;
  -webkit-box-pack: justify;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  padding: 10px;
  margin: 0px;
  background-image: none;
  column-gap: unset !important;
}
.iZVacQ {
  display: flex;
  flex-direction: column;
  grid-column: 1 / 13;
}
.cEEsxv {
  margin: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.bGDoVD {
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: 1px none;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  word-break: break-word;
  outline: none;
  color: rgb(255, 255, 255);
  width: auto;
  padding: 18px 60px;
  font-size: 22px;
  align-self: center;
  font-style: normal;
  background: #ff914d;
  font-family: InterSemiBold;
  font-weight: 600;
  line-height: 30px;
  border-radius: 50px;
  display: flex !important;
}
.hhIXUe {
  display: block;
  max-width: 100%;
  object-fit: contain;
  width: 971px;
}

.eJrUdH {
  position: static;
  display: flex;
  padding: 60px 40px 0px;
  background-size: initial;
  background-color: rgba(248, 248, 248, 0);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
}
.ixefHL {
  max-width: 1120px;
  width: 100%;
  margin: auto;
  background-color: transparent;
}
.csMzVy {
  display: grid;
  -webkit-box-pack: justify;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  padding: 10px;
  margin: 0px;
  background-size: initial;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
  column-gap: unset !important;
}
.iZVacQ {
  display: flex;
  flex-direction: column;
  grid-column: ~"1/13";
}
.kKOQTN {
  display: grid;
  -webkit-box-pack: justify;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  padding: 0px;
  column-gap: 20px;
  margin: 0px 0px 120px;
  background-size: initial;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
}
.gPGeRt {
  display: flex;
  flex-direction: column;
  grid-column: ~"1/7";
}
.bvyQFJ {
  text-align: initial;
  display: flex;
  flex-direction: column;
  margin: 0px;
}
.jezVrB {
  align-self: flex-start;
}
.dIqBze {
  display: block;
  position: relative;
  height: 0px;
  overflow: visible;
  padding-bottom: 66.67%;
}
.eLEaKC {
  display: block;
  max-width: 100%;
  object-fit: contain;
  width: 540px;
}
.dHDOqC {
  display: flex;
  flex-direction: column;
  grid-column: ~"7/13";
}
.cIthNr {
  text-align: initial;
  margin: 8px 0px 16px;
}
.hojTyf {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 20px;
  color: rgb(17, 17, 17);
  font-family: InterBold;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  line-height: 24px;
}
.hojTyf h1,
.hojTyf h2,
.hojTyf h3,
.hojTyf h4,
.hojTyf h5,
.hojTyf h6 {
  font-size: 20px;
  color: rgb(17, 17, 17);
  font-family: InterBold;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  line-height: 24px;
}
.cIthNr {
  text-align: initial;
  margin: 8px 0px 16px;
}
.fMsrgN {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 34px;
  color: rgb(17, 17, 17);
  font-family: InterBold;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  line-height: 42px;
}
.fbLrCr {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 18px;
  color: rgb(102, 102, 102);
  font-family: Inter;
  text-align: left;
  line-height: 28px;
}
.hHjwAs {
  display: grid;
  -webkit-box-pack: justify;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  padding: 0px;
  column-gap: 20px;
  margin: 0px 0px 120px;
  background-size: initial;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
}
.gPGeRt {
  display: flex;
  flex-direction: column;
  grid-column: ~"1/7";
}
.cIthNr {
  text-align: initial;
  margin: 8px 0px 16px;
}
.cCttZP {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 20px;
  color: rgb(17, 17, 17);
  font-family: InterBold;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  line-height: 24px;
}
.cIthNr {
  text-align: initial;
  margin: 8px 0px 16px;
}
.ekKOuy {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 34px;
  color: rgb(17, 17, 17);
  font-family: InterBold;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  line-height: 42px;
}
.gMJANh {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 18px;
  color: rgb(102, 102, 102);
  font-family: Inter;
  text-align: left;
  line-height: 28px;
}
.dZCzyk {
  text-align: initial;
  display: flex;
  flex-direction: column;
  margin: 0px;
}
.fOcChx {
  margin: 0px;
}
.kFOJwP {
  position: static;
  display: flex;
  padding: 80px 40px;
  background-size: initial;
  background-color: #0B2244;
  // background-color: rgb(244, 35, 103);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
}
.ixefHL {
  max-width: 1120px;
  width: 100%;
  margin: auto;
  background-color: transparent;
}
.iHOpMX {
  display: grid;
  -webkit-box-pack: justify;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  padding: 0px;
  margin: 0px;
  background-size: initial;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
  column-gap: unset !important;
}
.iZVacQ {
  display: flex;
  flex-direction: column;
  grid-column: ~"1/13";
}
.iVtKCp {
  text-align: initial;
  margin: 0px 0px 32px;
}
.RQGJd {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 24px;
  color: rgb(17, 17, 17);
  font-family: InterBold;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -1px;
  text-align: left;
  line-height: 30px;
}
.dbEuwm {
  text-align: initial;
  margin: 0px 0px 16px;
}
.jCLzJT {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 66px;
  color: rgb(17, 17, 17);
  font-family: InterBold;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -1px;
  text-align: left;
  line-height: 72px;
}

.ixefHL {
  max-width: 1120px;
  width: 100%;
  margin: auto;
  background-color: transparent;
}
.iHOpMX {
  display: grid;
  -webkit-box-pack: justify;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  padding: 0px;
  margin: 0px;
  background-size: initial;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
  column-gap: unset !important;
}
.eFGVkq {
  position: static;
  display: flex;
  padding: 60px 40px 40px;
  background-size: initial;
  background-color: #0B2244;
  // background-color: rgb(31, 31, 31);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
}
.iZVacQ {
  display: flex;
  flex-direction: column;
  grid-column: ~"1/13";
}
.hnVrSq {
  display: grid;
  -webkit-box-pack: justify;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  padding: 0px;
  column-gap: 20px;
  margin: 0px 0px 60px;
  background-size: initial;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-attachment: initial;
  background-image: none;
}
.eSkcLK {
  display: flex;
  flex-direction: column;
  grid-column: ~"1 / 3";
}
.kscyLG {
  display: flex;
  flex-direction: column;
  grid-column: ~"3 / 11";
}
.hplxCI {
  text-align: initial;
  margin: 0px 0px 24px;
}
.GzvSW {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 62px;
  color: rgb(17, 17, 17);
  font-family: InterBold;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -1px;
  text-align: center;
  line-height: 70px;
}
.fDnbHW {
  margin: 0px 0px 32px;
}
.bzrrWh {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 20px;
  color: rgb(102, 102, 102);
  font-family: Inter;
  text-align: center;
  line-height: 32px;
}
.cEEsxv {
  margin: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.iGemEQ {
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: 1px none;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  word-break: break-word;
  outline: none;
  color: rgb(255, 255, 255);
  width: auto;
  padding: 20px 60px;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  background: #ff914d;
  font-family: InterSemiBold;
  font-weight: 600;
  line-height: 30px;
  border-radius: 50px;
  display: flex !important;
}
@media screen and (max-device-width: 1024px) {
  .chat-preview {
    padding: 4% 20px;
  }
  .intro {
    padding: 0 20px;
    .title {
      font-size: 4.9vw;
    }

    .desc {
      font-size: 2vw;
      line-height: 4vw;
    }
  }
  .detail-intro {
    padding: 4% 40px;
    .intro-item {
      .desc-wrap {
        .title {
          font-size: 20px;
        }
        .desc {
          font-size: 13px;
        }
      }
    }
  }
  .chat-preview {
    .message {
      .content {
        .text {
          font-size: 15px;
          line-height: 18px;
        }
        .date {
          font-size: 15px;
        }
      }
    }
  }
  .function {
    padding: 5% 20px;
  }
}
@media screen and (max-device-width: 567px) {
  .intro {
    // .title {
    //   font-size: 4.8vw;
    //   padding: 0;
    // }

    .desc {
      font-size: 3.4vw;
      line-height: 6vw;
    }

    .jump-to-chat-btn {
      padding: 2% 6%;
      font-size: 3.5vw;
    }
  }
  .chat-preview {
    .message {
      .content {
        .text {
          font-size: 14px;
        }
        .date {
          font-size: 14px;
        }
      }
    }
  }
  .detail-intro {
    .intro-item {
      display: block;

      .desc-wrap {
        .title {
          font-size: 18px;
        }
        .desc {
          font-size: 12px;
        }
      }

      .img-wrap {
        text-align: center;
        margin-top: 4%;
        height: 200px;

        .img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .EAAub {
    display: flex;
    flex-direction: column;
    grid-template-columns: auto;
    padding: 0px;
    column-gap: 20px;
    margin: 0px;
    background-size: initial;
    background-repeat: repeat;
    background-position: 50% 50%;
    background-attachment: initial;
  }
  .bSkAlH {
    width: 100%;
    grid-column: ~"1 / 12";
    padding-left: 0px;
    padding-right: 0px;
  }
  .iRgSWM {
    width: 100%;
    grid-column: ~"1 / 12";
    padding-left: 0px;
    padding-right: 0px;
  }
  .hplxCI {
    margin: 0px 0px 16px;
  }
  .kznxsL {
    font-size: 44px;
    font-family: InterBold;
    letter-spacing: -1px;
    text-align: left;
    line-height: 52px;
  }
  .fDnbHW {
    margin: 0px 0px 32px;
  }
  .bzrrWh {
    padding: 0px;
    font-size: 18px;
    text-align: left;
    line-height: 28px;
  }
  .gqAbcT {
    width: 100%;
    grid-column: ~"1 / 12";
    padding-left: 0px;
    padding-right: 0px;
  }
  .bDuFbF {
    margin: 0px;
  }
  .epmnoT {
    // width: 100%;
    padding: 40px 20px 0px;
    background-size: initial;
    background-color: rgb(248, 248, 248);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-attachment: initial;
    background-image: none;
  }
  .fhBPKm {
    display: flex;
    flex-direction: column;
    grid-template-columns: auto;
    padding: 5px;
    column-gap: 20px;
    margin: 0px;
    background-size: initial;
    background-repeat: repeat;
    background-position: 50% 50%;
    background-attachment: initial;
  }
  .IgvUx {
    display: flex;
    flex-direction: column;
    grid-template-columns: auto;
    padding: 5px;
    column-gap: 20px;
    margin: 0px;
  }
  .iZVacQ {
    width: 100%;
    grid-column: ~"1/12";
    padding-left: 0px;
    padding-right: 0px;
  }
  .cEEsxv {
    margin: 0px 0px 32px;
  }
  .bGDoVD {
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 15px 30px;
    font-size: 18px;
    align-self: center;
    font-style: normal;
    background: #ff914d;
    font-family: InterSemiBold;
    font-weight: 600;
    line-height: 27px;
    border-style: none;
    border-width: 1px;
    border-radius: 50px;
  }
  .eJrUdH {
    // width: 100%;
    padding: 40px 20px 60px;
    background-size: initial;
    background-color: rgba(248, 248, 248, 0);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-attachment: initial;
    background-image: none;
  }
  .ixefHL {
    background-color: transparent;
  }
  .csMzVy {
    display: flex;
    flex-direction: column;
    grid-template-columns: auto;
    padding: 0px;
    column-gap: 20px;
    margin: 0px;
    background-size: initial;
    background-repeat: repeat;
    background-position: 50% 50%;
    background-attachment: initial;
  }
  .iZVacQ {
    width: 100%;
    grid-column: ~"1/12";
    padding-left: 0px;
    padding-right: 0px;
  }
  .kKOQTN {
    display: flex;
    flex-direction: column-reverse;
    grid-template-columns: auto;
    padding: 0px;
    column-gap: 20px;
    margin: 0px 0px 16px;
    background-size: initial;
    background-repeat: repeat;
    background-position: 50% 50%;
    background-attachment: initial;
  }
  .gPGeRt {
    width: 100%;
    grid-column: ~"1/12";
    padding-left: 0px;
    padding-right: 0px;
  }
  .bvyQFJ {
    margin: 0px 0px 24px;
  }
  .cIthNr {
    margin: 0px 0px 16px;
  }
  .hojTyf {
    padding: 0px;
    font-size: 16px;
    font-family: InterBold;
    text-align: left;
    line-height: 19.2px;
  }
  .hojTyf h1,
  .hojTyf h2,
  .hojTyf h3,
  .hojTyf h4,
  .hojTyf h5,
  .hojTyf h6 {
    font-size: 16px;
    font-family: InterBold;
    text-align: left;
    line-height: 19.2px;
  }
  .cIthNr {
    margin: 0px 0px 16px;
  }
  .fMsrgN {
    padding: 0px;
    font-size: 28px;
    font-family: InterBold;
    text-align: left;
    line-height: 32px;
  }
  .jxlMFl {
    margin: 0px 0px 24px;
  }
  .fbLrCr {
    padding: 0px;
    font-size: 16px;
    text-align: left;
    line-height: 24px;
  }
  .hHjwAs {
    display: flex;
    flex-direction: column;
    grid-template-columns: auto;
    padding: 0px;
    column-gap: 20px;
    margin: 0px 0 36px;
    background-size: initial;
    background-repeat: repeat;
    background-position: 50% 50%;
    background-attachment: initial;
  }
  .gPGeRt {
    width: 100%;
    grid-column: ~"1/12";
    padding-left: 0px;
    padding-right: 0px;
  }
  .cIthNr {
    margin: 0px 0px 16px;
  }
  .cCttZP {
    padding: 0px;
    font-size: 16px;
    font-family: InterBold;
    line-height: 19.2px;
  }
  .cIthNr {
    margin: 0px 0px 16px;
  }
  .ekKOuy {
    padding: 0px;
    font-size: 28px;
    font-family: InterBold;
    line-height: 32px;
  }
  .jxlMFl {
    margin: 0px 0px 24px;
  }
  .gMJANh {
    padding: 0px;
    font-size: 16px;
    line-height: 24px;
  }
  .dHDOqC {
    width: 100%;
    grid-column: ~"1/12";
    padding-left: 0px;
    padding-right: 0px;
  }
  .dZCzyk {
    margin: 0px;
  }
  .kFOJwP {
    // width: 100%;
    padding: 60px 20px;
    background-size: initial;
    background-color: #0B2244;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-attachment: initial;
    background-image: none;
  }
  .iHOpMX {
    display: flex;
    flex-direction: column;
    grid-template-columns: auto;
    padding: 0px;
    column-gap: 20px;
    margin: 0px;
    background-size: initial;
    background-repeat: repeat;
    background-position: 50% 50%;
    background-attachment: initial;
  }
  .iZVacQ {
    width: 100%;
    grid-column: ~"1/12";
    padding-left: 0px;
    padding-right: 0px;
  }
  .iVtKCp {
    margin: 0px 0px 24px;
  }
  .RQGJd {
    font-size: 20px;
    font-family: InterBold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 32px;
  }
  .dbEuwm {
    margin: 0px 0px 16px;
  }
  .jCLzJT {
    font-size: 32px;
    font-family: InterBold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 44px;
  }

  .function {
    .func-wrap {
      display: block;
    }
    .func-item {
      margin-bottom: 6%;
      padding: 30px 30px 35px;
      text-align: left;
      &:last-child {
        margin-bottom: 0;
      }
      .icon {
        margin: 0 0 24px;
        font-size: 60px;
      }
      .title {
        margin: 0px 0px 16px;
        font-size: 24px;
        font-family: InterBold;
        letter-spacing: 0px;
        line-height: 30px;
        color: rgb(17, 17, 17);
      }
      .desc {
        padding: 0;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .eFGVkq {
    // width: 100%;
    padding: 60px 20px 40px;
    background-size: initial;
    background-color: #0B2244;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-attachment: initial;
    background-image: none;
  }
  .iHOpMX {
    display: flex;
    flex-direction: column;
    grid-template-columns: auto;
    padding: 0px;
    column-gap: 20px;
    margin: 0px;
    background-size: initial;
    background-repeat: repeat;
    background-position: 50% 50%;
    background-attachment: initial;
  }
  .iZVacQ {
    width: 100%;
    grid-column: ~"1/12";
    padding-left: 0px;
    padding-right: 0px;
  }
  .hnVrSq {
    display: flex;
    flex-direction: column;
    grid-template-columns: auto;
    padding: 0px;
    column-gap: 20px;
    margin: 0px 0px 40px;
    background-size: initial;
    background-repeat: repeat;
    background-position: 50% 50%;
    background-attachment: initial;
  }
  .eSkcLK {
    width: 100%;
    grid-column: ~"1 / 12";
    padding-left: 0px;
    padding-right: 0px;
  }
  .kscyLG {
    width: 100%;
    grid-column: 1 / 12;
    padding-left: 0px;
    padding-right: 0px;
  }
  .hplxCI {
    margin: 0px 0px 16px;
  }
  .GzvSW {
    font-size: 44px;
    font-family: InterBold;
    letter-spacing: -1px;
    text-align: left;
    line-height: 52px;
  }
  .fDnbHW {
    margin: 0px 0px 32px;
  }
  .bzrrWh {
    padding: 0px;
    font-size: 18px;
    text-align: left;
    line-height: 28px;
  }
  .cEEsxv {
    margin: 0px 0px 32px;
  }
  .iGemEQ {
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 15px 30px;
    font-size: 18px;
    align-self: center;
    font-style: normal;
    background: #FF914D;
    font-family: InterSemiBold;
    font-weight: 600;
    line-height: 27px;
    border-style: none;
    border-width: 1px;
    border-radius: 50px;
  }
}
</style>
